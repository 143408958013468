import { Item } from '../components/Fields/Variant.sku';
import { Box, Flex, Text, Stack } from '@contentful/f36-components';

interface ItemDetails {
  sku: string;
  name: string;
  inventory: string | number;
  color: string;
  style: string;
  replenishable: string;
  siteActive: string;
  season: string;
}

/* Compile all sizes for Pack SKUs */
const compileSizes = (components: Item['KitComponents']) => {
  const sizes = components?.reduce((s, c) => {
    const size = c?.ComponentItem?.Sku?.ProductSize?.node_name;
    if (size) s.add(size);
    return s;
  }, new Set());
  return Array.from(sizes).join(' / ');
};

const compileSeasons = (
  kitComponents: Array<{
    ComponentItem: Item;
  }> = []
): string => {
  const seasons = new Set();

  kitComponents.forEach((component) => {
    const seasonCode = component.ComponentItem?.Sku?.Season?.code;
    seasonCode && seasons.add(seasonCode);
  });

  const array = Array.from(seasons);
  if (array.length) return array.join(', ');
  return 'Missing from PLM';
};

/**
 * Compile Item's Relevant Data for Display
 * Relevant data location dependent upon whether Item is a virtual kit or single SKU item
 */
export const getItemDetails = (item: Item): ItemDetails => {
  return item?.Sku?.id
    ? {
        sku: `${item?.sku} - ${item?.Sku?.Style?.name}`,
        inventory: `(${
          item?.InventoryItems?.find((x) => x.Store.region === 'US')
            ?.available || '0'
        })`,
        name: item?.Sku?.Style?.site_name,
        style: `${item?.Sku?.Style?.StyleTag?.node_name} - ${item?.Sku?.Style?.style_number}`,
        color: item?.Sku?.Colorway?.site_color || 'Missing from PLM',
        replenishable: item?.Sku?.Colorway?.core_fashion || 'Missing from PLM',
        siteActive: item?.Sku?.site_active ? 'TRUE' : 'FALSE',
        season: item?.Sku?.Season?.code || 'Missing from PLM'
      }
    : {
        sku: item?.sku,
        inventory: `(${item.KitComponents?.reduce((inv, comp) => {
          const componentInventory = Number(
            comp.ComponentItem.InventoryItems?.find(
              (x) => x.Store.region === 'US'
            )?.available || 0
          );
          if (inv > componentInventory) {
            return componentInventory;
          } else {
            return inv;
          }
        }, 0)})`,
        name: item.name,
        style: 'N/A',
        color: item.site_color,
        replenishable: item.KitComponents?.every(
          (comp) => comp.ComponentItem?.Sku?.Colorway?.core_fashion === 'CORE'
        )
          ? 'CORE'
          : 'FASHION',
        siteActive: item.KitComponents?.every(
          (comp) => comp.ComponentItem?.Sku?.site_active
        )
          ? 'TRUE'
          : 'FALSE',
        season: compileSeasons(item.KitComponents)
      };
};

/**
 * Render SKU/Variant Search Result List in Product and Variant Models
 * Usage in Product and Variant models Autocomplete renderItem prop
 */
export const renderVariantItem = (item: Item) => {
  const itemDetails = getItemDetails(item);

  return (
    <Flex flexDirection="column">
      <Box>
        <Text fontWeight="fontWeightMedium">SKU: {itemDetails.sku} </Text>
        <Text>{itemDetails.inventory}</Text>
      </Box>
      <Stack
        flexDirection="column"
        flexWrap="wrap"
        spacing="none"
        alignItems="left"
      >
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Marketing Name: {itemDetails.name}
          </Text>
        </Box>
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Style: {itemDetails.style}
          </Text>
        </Box>
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Color: {itemDetails.color}
          </Text>
        </Box>
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Replenishment: {itemDetails.replenishable}
          </Text>
        </Box>
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Season: {itemDetails.season}
          </Text>
        </Box>
        <Box>
          <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
            Site Active: {itemDetails.siteActive}
          </Text>
        </Box>
      </Stack>
    </Flex>
  );
};

/* Create Variant Model Title from Item's Name, Color & Size data */
export const generateVariantTitle = (item: Item) => {
  let name;
  let color;
  let size;

  if (item?.Sku?.id) {
    name = item?.Sku?.Style?.site_name;
    color = item?.Sku?.Colorway?.site_color;
    size = item?.Sku?.ProductSize?.node_name;
  } else {
    name = item.name;
    color = item.site_color;
    size = compileSizes(item?.KitComponents);
  }

  return `${name} - ${[color, size].filter((i) => i).join(' / ')}`;
};
