import React, { useState, useEffect, CSSProperties, useCallback } from 'react';
import { SidebarExtensionSDK } from '@contentful/app-sdk';
import { Button } from '@contentful/f36-components';
import { ExternalLinkIcon } from '@contentful/f36-icons';

interface PageSidebarProps {
  sdk: SidebarExtensionSDK;
}

enum Disabled {
  Enabled = 0,
  Disabled = 1
}
interface ElementProps {
  href: string;
  tabIndex?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
}

const PageSidebar = ({ sdk }: PageSidebarProps) => {
  const [isDisabled, setIsDisabled] = useState<Disabled>(Disabled.Disabled);
  const [modelRequirementsMet, setRequirementsMet] = useState<boolean>(false);
  const [pagePath, setPagePath] = useState<string>('');

  const endpointProd = 'https://bombas.com';
  const endpointStaging = 'https://bombas.fyi';

  const areRequirementsMet = useCallback(() => {
    setIsDisabled(Disabled.Disabled);
    const requiredFields = Object.keys(sdk.entry.fields).filter(
      (field) => sdk.entry.fields[field].required
    );
    const requirementsMet = requiredFields.every(
      (field) => sdk.entry.fields[field].getValue()?.length
    );
    setRequirementsMet(requirementsMet);
  }, [sdk]);

  useEffect(() => {
    const unsubscribeChanges = sdk.entry.onSysChanged(areRequirementsMet);

    return () => {
      unsubscribeChanges();
    };
  }, [areRequirementsMet, sdk.entry]);

  useEffect(() => {
    const unsubscribePath = sdk.entry.fields.url.onValueChanged((path) => {
      setIsDisabled(Disabled.Disabled);
      setPagePath(path || '');
    });

    return () => {
      unsubscribePath();
    };
  }, [sdk.entry.fields.url]);

  const pageType = pagePath.includes('product')
    ? 'pdp'
    : pagePath.includes('collection')
    ? 'pcp'
    : pagePath.includes('page')
    ? 'page'
    : '';
  const pagePreviewLink = `${
    sdk.ids.environment === 'main' ? endpointProd : endpointStaging
  }${pagePath}?preview=page`;

  if (modelRequirementsMet && pageType.length && isDisabled) {
    setIsDisabled(Disabled.Enabled);
  }

  const buttonProperties: ElementProps = {
    href: isDisabled ? '#' : pagePreviewLink
  };
  if (isDisabled) {
    buttonProperties.tabIndex = -1;
    buttonProperties.style = { pointerEvents: 'none' };
    buttonProperties.isDisabled = true;
  }

  return (
    <Button
      as="a"
      target="_blank"
      rel="noopener"
      variant="secondary"
      isFullWidth
      startIcon={<ExternalLinkIcon />}
      {...buttonProperties}
    >
      Open preview
    </Button>
  );
};

export default PageSidebar;
