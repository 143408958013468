import React, { useState, useEffect, useCallback } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import {
  TextInput,
  Stack,
  FormControl,
  ValidationMessage
} from '@contentful/f36-components';

interface FieldValues {
  mobile: string;
  tablet: string;
  desktop: string;
  [key: string]: string;
}

const StyleSettingBreakPointGroup = ({ sdk }: { sdk: FieldExtensionSDK }) => {
  const [values, setValues] = useState<FieldValues>({
    mobile: '',
    tablet: '',
    desktop: ''
  });
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>('Invalid Format');
  const [relativeToBrowserWidthValue, setRelativeToBrowserWidthValue] =
    useState<boolean | undefined>(false);
  const currentFieldName = sdk.field.id;
  const acceptFourValues =
    currentFieldName === 'internalSpace'
  /**
   * Reads the "relativeToBrowserWidth" field, and if it exists:
   * Listens for changes to the value.
   * If the value is changed, then we update the state to its new value.
   */
  sdk.entry?.fields?.relativeToBrowserWidth?.onValueChanged(() => {
    if (
      sdk.entry.fields?.relativeToBrowserWidth.getValue() !==
      relativeToBrowserWidthValue
    ) {
      setRelativeToBrowserWidthValue(
        sdk.entry.fields?.relativeToBrowserWidth.getValue()
      );
    }
  });

  /**
   * Updates UI to show field's validity based on:
   * The current values of the breakpointGroup matching regex validation
   */
  const checkIfFormatInvalid = useCallback(
    (obj: FieldValues) => {
      /**
       * Regex pattern to match:
       * Empty values,
       * Single Integers or decimals,
       * Groups of three integers or decimals separated by commas.
       */
      const singleOrThreeNumExp = new RegExp(
        /^(?:\d+(\.\d+)?|\d+(\.\d+)?,\d+(\.\d+)?,\d+(\.\d+)?)?$/
      );
      /**
       * Regex pattern to match:
       * - Empty values,
       * - Up to 4 integers or decimals separated by commas,
       * - Each value can be an integer, a decimal, or empty.
       */
      const fourNumbersExp = new RegExp(
        /^(\d+(\.\d+)?|)(,(\d+(\.\d+)?|)){0,3}$/
      );
      /**
       * Regex pattern to match:
       * Empty values,
       * Single Integers or decimals.
       */
      const singleNumExp = new RegExp(/^(?:\d*\.?\d+|\d+\.\d*)?$/);

      let invalidMatch;

      if (relativeToBrowserWidthValue) {
        invalidMatch = Object.values(obj).some(
          (value) => !singleOrThreeNumExp.test(value)
        );
        setValidationMessage('Invalid Format. Please enter a single number or a group of 3 numbers separated by commas. Example: 3 or 1,2,3');
      } else if (acceptFourValues) {
        invalidMatch = Object.values(obj).some(
          (value) => !fourNumbersExp.test(value)
        );
        setValidationMessage('Invalid Format. Please enter up to 4 numbers separated by commas. Example: 1,2,3,4');
      } else {
        invalidMatch = Object.values(obj).some(
          (value) => !singleNumExp.test(value)
        );
        setValidationMessage('Invalid Format. Please enter a single number or decimal. Example: 1 or 1.5')
      }

      /*
       * checks this field's value. if true, then we allow a group of 3 numbers separated by commas.
       */
      if (invalidMatch) {
        setIsInvalid(true);
        sdk.field.removeValue();
      } else {
        setIsInvalid(false);
        sdk.field.setValue(obj);
      }
    },
    [acceptFourValues, relativeToBrowserWidthValue, sdk.field]
  );

  useEffect(() => {
    // Adjust iframe size based on content
    sdk.window.startAutoResizer({ absoluteElements: false });
    // Loads initial field values when component mounts
    const fieldValue = sdk.field.getValue() as FieldValues;
    if (fieldValue) {
      setValues(fieldValue);
    }
  }, [sdk.field, sdk.window]);

  /**
   * Validates initial field values when component mounts and remounts
   */
  useEffect(() => checkIfFormatInvalid(values), [checkIfFormatInvalid, values]);

  const handleChange =
    (fieldName: keyof FieldValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value
      }));
      const updatedValues = { ...values, [fieldName]: value };

      // If field is required, check if any of the values are empty
      const isFieldRequired = sdk.field.required;
      if (isFieldRequired) {
        if (checkIfInvalid(updatedValues)) {
          setIsInvalid(true);
          sdk.field.removeValue();
        } else {
          checkIfFormatInvalid(updatedValues);
        }
      } else {
        checkIfFormatInvalid(updatedValues);
      }
    };

  function checkIfInvalid(obj: FieldValues) {
    return Object.values(obj).some((value) => value === '');
  }

  return (
    <FormControl
      marginBottom="spacing2Xs"
      marginLeft="spacing2Xs"
      isInvalid={isInvalid}
    >
      <Stack flexDirection="row">
        <Stack flexDirection="column" spacing="none" alignItems="flex-start">
          <FormControl.Label>Mobile</FormControl.Label>
          <TextInput
            size="small"
            value={values.mobile}
            onChange={handleChange('mobile')}
          />
        </Stack>
        <Stack flexDirection="column" spacing="none" alignItems="flex-start">
          <FormControl.Label>Tablet</FormControl.Label>
          <TextInput
            size="small"
            value={values.tablet}
            onChange={handleChange('tablet')}
          />
        </Stack>
        <Stack flexDirection="column" spacing="none" alignItems="flex-start">
          <FormControl.Label>Desktop</FormControl.Label>
          <TextInput
            size="small"
            value={values.desktop}
            onChange={handleChange('desktop')}
          />
        </Stack>
      </Stack>
      {isInvalid && <ValidationMessage>{validationMessage}</ValidationMessage>}
    </FormControl>
  );
};

export default StyleSettingBreakPointGroup;
