import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Stack,
  Card,
  Button,
  ButtonGroup,
  Flex,
  Select,
  IconButton,
  Box,
  FormControl,
  Pill,
  Text,
  Note,
  Checkbox
} from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { client, gql } from '../services/graphql';
import { completeLoading } from '../Dialogs/Loading';
import { Entry, PlainClientAPI } from 'contentful-management';

import './DynamicProductGrid.referenceAttributes.css';

interface DynamicProductGridReferenceAttributesFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

interface ReferenceAttribute {
  id: string;
  key: string;
  values: Array<string>;
  excluded: boolean;
}

type CmsProductReference = {
  productId: string;
  productCardId?: string;
};

enum AttributeKey {
  'key' = 'key',
  'values' = 'values',
  'excluded' = 'excluded',
}

export enum SortDirection {
  MANUAL = 'MANUAL',
  BEST_SELLING = 'BEST_SELLING'
}

interface Item {
  id: string;
  type: string;
  placement: number;
}

interface LinkedItems {
  contentCards: Array<Item>;
  products: Array<Item>;
}

type ReferenceAttributes = Record<string, Array<string>>;

// year is hardcoded up to 2030. We can re-evaluate that later.
const VALID_ATTRIBUTES: ReferenceAttributes = {
  code: ['CORE', 'SP22', 'SU22', 'FA22', 'HO22', 'SP23', 'SU23', 'FA23', 'HO23', 'SP24', 'SU24', 'FA24', 'HO24', 'SP25', 'SU25', 'FA25', 'HO25'],
  has_pocket: ['true', 'false'],
  has_gripper: ['true', 'false'],
};

export interface FieldState {
  sort: SortDirection;
  values: Array<ReferenceAttribute>;
}

const DynamicProductGridReferenceAttributesField = ({
  sdk,
  cma
}: DynamicProductGridReferenceAttributesFieldProps) => {
  const contentfulValue = sdk.field.getValue();
  const [fieldValue, setFieldValue] = useState<FieldState>(
    (contentfulValue as FieldState) || {
      sort: SortDirection.MANUAL,
      values: []
    }
  );
  const [validReferenceAttributes, setValidReferenceAttributes] = useState(VALID_ATTRIBUTES);

  useEffect(() => {
    client(sdk.ids.environment)
      .request(
        gql`
          query getReferenceAttributes {
            referenceAttributes {
              sockWeight
              sockHeight
              neckCollarType
              endUse
              category
              cut
              sleeveType
              subclass
              color
              patternGroup
              collection
              primaryMaterial
              percentOff
              prepackSize
              age
              gender
              elevated
              waistRise
              uwCoverage
            }
          }
        `,
        {}
      )
      .then(({ 
        referenceAttributes: {
          sockWeight,
          sockHeight,
          neckCollarType,
          endUse,
          category,
          cut,
          sleeveType,
          subclass,
          color,
          patternGroup,
          collection,
          primaryMaterial,
          percentOff,
          prepackSize,
          age,
          gender,
          elevated,
          waistRise,
          uwCoverage,
        }
      }) => {
        setValidReferenceAttributes((v) => ({
          ...v,
          sock_weight: sockWeight,
          sock_height: sockHeight,
          neck_collar_type: neckCollarType,
          end_use: endUse,
          category,
          cut,
          sleeve_length: sleeveType,
          sub_class: subclass,
          color,
          pattern_group: patternGroup,
          collection,
          material: primaryMaterial,
          percent_off: percentOff,
          prepack_size: prepackSize,
          age,
          gender,
          elevated,
          waist_rise: waistRise,
          uw_coverage: uwCoverage
        })
      )}
    );
  }, []);

  const setContentfulValue = async (val: {
    sort: SortDirection;
    values: Array<ReferenceAttribute>;
  }) => {
    const sanitizedValues = val.values.filter(
      (v) => v.key !== 'invalid' && v.values.length
    );

    if (sanitizedValues.length) {
      const updated = { sort: val.sort, values: sanitizedValues };

      sdk.dialogs.openCurrentApp({
        width: 400,
        parameters: {
          key: 'loading',
          message: 'Generating product grid'
        }
      });

      client(sdk.ids.environment)
        .request(
          gql`
            query getProductsCmsRef($q: ProductReferenceInput) {
              cmsProductReferences(query: $q) {
                productId
              }
            }
          `,
          {
            q: {
              sort: val.sort,
              attributes: val.values.map((v) => ({
                key: v.key,
                values: v.values,
                excluded: v.excluded
              }))
            }
          }
        )
        .then(
          async (r: { cmsProductReferences: Array<CmsProductReference> }) => {
            await sdk.field.setValue(updated);

            // get all current items in the grid and their entry data
            const currentItems = await cma.entry.references({
              entryId: sdk.entry.getSys().id,
              include: 1
            });

            // translate current grid item data to object that includes content type, placement in the grid, and entry id
            const linkedEntryData = currentItems?.includes?.Entry;
            const linkedItems = currentItems.items[0].fields?.items?.[sdk.locales.default]?.map((item: Entry, index: number) => {
              const entryId = item.sys.id;
              const entryData = linkedEntryData?.find(
                (entry) => entry.sys.id === entryId
              );

              return {
                id: entryId,
                type: entryData?.sys.contentType.sys.id,
                placement: index,
              }
            }) || [];
           
            // separate current grid items into products vs. content system cards
            const { contentCards, products } = linkedItems.reduce(
              (accum: LinkedItems, item: Item) => {
                if (item.type !== 'product') {
                  accum.contentCards.push(item);
                  return accum;
                }

                accum.products.push(item);
                return accum;
              },
              {
                contentCards: [],
                products: []
              }
            );

            // compile lists of product ids from the new product set and current product set for filtering/comparing
            const newProductIds = r.cmsProductReferences.map(
              (p) => p.productId
            );
            const oldProductIds = products.map((p: Item) => p.id);

            // determine products that should carry over and new products that should be added at the top of the grid
            const carryoverProducts = products.filter((p: Item) =>
              newProductIds.includes(p.id)
            );
            const newProducts = r.cmsProductReferences.filter(
              (p) => !oldProductIds.includes(p.productId)
            );

            // create new item set with all products and content system cards
            // content system cards should remain at the same place in the grid
            // new products should be added to the front of the grid
            let newItemsSet = [...newProducts, ...carryoverProducts];
            contentCards.forEach((card: Item) => {
              const start = newItemsSet.slice(0, card.placement);
              const end = newItemsSet.slice(card.placement);
              newItemsSet = [...start, card, ...end];
            });

            await sdk.entry.fields.items.setValue(
              newItemsSet.map((item) => ({
                sys: {
                  type: 'Link',
                  linkType: 'Entry',
                  id: item.id || item.productId
                }
              }))
            );
            await completeLoading();
          }
        )
        .catch(async () => {
          await completeLoading();

          sdk.dialogs.openAlert({
            title: 'An Error Occurred',
            message:
              'Uh oh, something went wrong. Please contact the Engineering team for support (reference: DynamicProductGrid Product Generation).'
          });
        });
    } else {
      sdk.entry.fields.items.setValue(undefined);
      sdk.field.setValue(undefined);
    }
  };
  const handleAddRow = () => {
    setFieldValue({
      sort: fieldValue.sort,
      values: [
        ...fieldValue.values,
        {
          id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
          key: 'invalid',
          values: [],
          excluded: false,
        }
      ]
    });
  };
  const handleUpdateRow = (
    rowId: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFieldValue((prevState) => {
      const next = prevState.values.map((obj) => {
        if (rowId === obj.id) {
          const key = event.target.name as keyof typeof AttributeKey;

          if (key === 'excluded') {
            return {
              ...obj,
              excluded: !obj.excluded
            };
          }

          if (key === 'key') {
            return {
              ...obj,
              key: event.target.value,
              values: [],
              excluded: false,
            };
          }

          return {
            ...obj,
            [key]: Array.from(new Set([...obj[key], event.target.value]))
          };
        }
        return obj;
      });
      return { sort: prevState.sort, values: next };
    });
  };
  const handleDeleteRow = (deleteId: string) =>
    setFieldValue((prevState) => {
      const next = prevState.values.filter(({ id }) => id !== deleteId);
      return { sort: prevState.sort, values: next };
    });
  const handleRemoveValue = (rowId: string, value: string) => {
    setFieldValue((prevState) => {
      const next = prevState.values.map((obj) => {
        if (rowId === obj.id)
          return { ...obj, values: obj.values.filter((v) => v !== value) };
        return obj;
      });
      return { sort: prevState.sort, values: next };
    });
  };
  const handleUpdateSort = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFieldValue((prevState) => ({
      ...prevState,
      sort: event.target.value as SortDirection
    }));
  };

  // Update height of field whenever new rows are added
  useEffect(() => {
    sdk.window.updateHeight();
  }, [sdk.window, fieldValue.values]);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      {!fieldValue.values.length && (
        <Note variant="warning">
          No reference attributes have been configured.
        </Note>
      )}
      {fieldValue.values.map(({ id, key, values: rowValues, excluded: isExcluded }) => (
        <Card key={id}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap="spacingS"
          >
            <Box style={{ width: '45%' }}>
              <FormControl.Label>Attribute</FormControl.Label>
              <Stack>
                <Box style={{ flex: 1 }}>
                  <Select
                    name="key"
                    value={key}
                    onChange={(e) => handleUpdateRow(id, e)}
                  >
                    <Select.Option value="invalid" isDisabled>
                      Pick an attribute
                    </Select.Option>
                    {Object.keys(validReferenceAttributes).map((name) => {
                      return (
                        <Select.Option value={name} key={name}>
                          {name}
                        </Select.Option>
                      );
                    })}
                    {key !== 'invalid' &&
                      !Object.keys(validReferenceAttributes).find((k) => k === key) && (
                        <Select.Option value={key}>
                          {key} (Invalid)
                        </Select.Option>
                      )}
                  </Select>
                </Box>
                <Select
                  name="values"
                  value="invalid"
                  isDisabled={!(key in validReferenceAttributes)}
                  onChange={(e) => handleUpdateRow(id, e)}
                  style={{ width: '75px' }}
                >
                  <Select.Option value="invalid" isDisabled>
                    Pick
                  </Select.Option>
                  {validReferenceAttributes[key as keyof typeof VALID_ATTRIBUTES]?.map(
                    (option) => {
                      return (
                        <Select.Option value={option} key={option}>
                          {option}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Stack>
            </Box>
            <Box style={{ width: '45%' }}>
              <FormControl.Label>Values</FormControl.Label>
              <Stack spacing="spacingXs" flexWrap="wrap">
                {rowValues.length ? (
                  rowValues.map((v) => (
                    <Pill
                      key={v}
                      label={v}
                      onClose={() => handleRemoveValue(id, v)}
                    />
                  ))
                ) : (
                  <Text fontColor="gray500" lineHeight="lineHeight2Xl">
                    No value(s) selected.
                  </Text>
                )}
              </Stack>
            </Box>
            <Box style={{ width: '10%' }}>
              <FormControl.Label>Exclude</FormControl.Label>
              <Checkbox
                className="bombas-referecence-attributes--excude"
                value={id}
                id={`option-${id}`}
                isChecked={isExcluded}
                name="excluded"
                onChange={(e) => handleUpdateRow(id, e)}
              />
            </Box>
            <IconButton
              variant="transparent"
              aria-label="Delete row"
              icon={<DeleteIcon />}
              onClick={() => handleDeleteRow(id)}
            />
          </Flex>
        </Card>
      ))}
      {fieldValue.values.length ? (
        <Card>
          <Stack>
            <Text fontWeight="fontWeightMedium">Product Sort</Text>
            <Select
              name="sort"
              value={fieldValue.sort}
              onChange={handleUpdateSort}
            >
              <Select.Option value={SortDirection.MANUAL}>Manual</Select.Option>
              <Select.Option value={SortDirection.BEST_SELLING}>
                Best Selling
              </Select.Option>
            </Select>
          </Stack>
          <Box paddingTop="spacingS">
            <Text fontColor="gray500">
              {fieldValue.sort === SortDirection.MANUAL
                ? 'Manual sorted grid allows you to customize the order of products and content modules in the grid. New products matching the reference attributes will be automatically added to the end of the item list.'
                : 'Best Selling sorted grid organizes products by best-to-worst selling, while allowing content modules to be placed anywhere in the grid. As products sell, matching product placement will adjust while content modules remain in the same position.'}
            </Text>
          </Box>
        </Card>
      ) : null}
      <ButtonGroup variant="spaced" spacing="spacingS">
        <Button onClick={handleAddRow}>Add item</Button> 
        <Button
          isDisabled={!fieldValue.values.length}
          onClick={async () => {
            const result = await sdk.dialogs.openConfirm({
              title: 'Are you sure?',
              message:
                'This will regenerate ALL items in this grid and cannot be undone.',
              intent: 'negative'
            });
            if (result) {
              console.log(fieldValue)
              setContentfulValue(fieldValue);
            }
          }}
        >
          Generate
        </Button>
      </ButtonGroup>
      <Text as="i" fontColor="gray500">
        Reference attributes form the conditions that products must meet to be
        included in the grid.
      </Text>
    </Stack>
  );
};

export default DynamicProductGridReferenceAttributesField;
