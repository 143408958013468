import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Stack, Text } from '@contentful/f36-components';
// TODO: Later enhancement will refactor to fetch valid attributes from server.
export const VALID_ATTRIBUTES = {
  gender: ['men', 'women', 'kids', 'toddler', 'unisex'],
  category: ['socks', 't-shirts', 'underwear', 'slipper', 'bralette'],
  height: ['no_show', 'ankle', 'quarter', 'calf', 'half_calf', 'knee_high'],
  neckline: ['crew', 'tank', 'v_neck'],
  sleeveLength: ['short', 'long', 'sleeveless'],
  cut: [
    'thong',
    'bikini',
    'hipster',
    'high_rise_hipster',
    'long_underwear',
    'brief',
    'trunk',
    'boxer',
    'boxer_brief',
    'high_rise_brief',
    'triangle',
    'scoop',
  ],
  elevated: ['true', 'false']
};

const BreadcrumbResourceReferenceAttributes = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField sdk={sdk} attributes={VALID_ATTRIBUTES} />
      <Text>
        Reference attributes are used to connect a breadcrumb to attributes on a
        product.
      </Text>
      <Text as="i" fontColor="gray500">
        Include as much information as possible for automatic breadcrumb
        generation to be effective. If the breadcrumb is for a Women's Calf
        Sock, the appropriate tags for gender, category, and sock_height should
        be added. If a breadcrumb is for Women's Socks, the appropriate tags for
        gender and category should be added.
      </Text>
    </Stack>
  );
};

export default BreadcrumbResourceReferenceAttributes;
