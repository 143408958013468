import { GraphQLClient } from 'graphql-request';

const endpointProd = 'https://platform.bombas.com/graphql';
const endpointStaging = 'https://platform.bombas.fyi/graphql';

export * from 'graphql-request';
export const client = (contentfulEnvironment: 'main' | 'staging' | string) =>
  new GraphQLClient(
    contentfulEnvironment === 'main' ? endpointProd : endpointStaging
  );
