import React, { useEffect, useState } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Button, Spinner, Text } from '@contentful/f36-components';
import { PlainClientAPI } from 'contentful-management';
import { client, gql } from '../services/graphql';
import { SingleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { ReferenceAttribute } from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { VALID_ATTRIBUTES as breadcrumbAttributes } from './BreadcrumbResource.referenceAttributes';

interface ProductBreadcrumbFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

// Lowercases and fills in spaces on a string
const reformatString = (s: string) => s.toLowerCase().replace(/ /, '_');

const DEMOGRAPHIC_SLUG = {
  Adult: {
    Male: 'Men',
    Female: 'Women',
    Unisex: 'Adult'
  },
  Youth: {
    Unisex: 'Youth'
  },
  Toddler: {
    Unisex: 'Toddler'
  },
  Baby: {
    Unisex: 'Baby'
  }
} as const;

type BreadcrumbDetail = {
  key: string;
  values: Array<string>;
};

type BreadcrumbLevels = {
  demographic?: Array<string>;
  categories?: Array<string>;
  details?: Array<BreadcrumbDetail>;
};

const componentFieldMap = Object.keys(breadcrumbAttributes);

type GenericComponentFieldObject = {
  [key: string]: string;
};

/**
 * A breadcrumb has 3 different sections- each referred to as a `slug`
 *
 * 1 - Gender
 * 2 - Category
 * 3 - Subcategory
 *
 * @example
 *
 * Women / Sock / Quarter
 * Men / Shirt / Crew
 */
const ProductBreadcrumb = ({ sdk, cma }: ProductBreadcrumbFieldProps) => {
  const [variants, setVariants] = useState(
    sdk.entry.fields.variants.getValue() || []
  );
  const [breadcrumbs, setBreadcrumbs] = useState(
    sdk.entry.fields.breadcrumb.getValue() || undefined
  );
  const [inProgress, setInProgress] = useState(false);

  const getBreadcrumbEntries = async ({
    demographic = [],
    categories = [],
    details = []
  }: BreadcrumbLevels) => {
    const query = {
      content_type: 'breadcrumbResource'
    } as const;

    // Get breadcrumb entries
    const entries = await cma.entry.getMany({
      query
    });

    const entryWithMatchingDetails = entries.items.find((e) => {
      const refAttributes: ReferenceAttribute[] =
        e.fields.referenceAttributes?.[sdk.field.locale];

      if (!refAttributes) {
        return undefined;
      }

      const refAttributesMatchesDetail: boolean = details.length
        ? refAttributes.some(({ key, values }) => {
            return details.some(
              (d) =>
                key === d.key.toLowerCase() &&
                d.values.some((v) => values.includes(reformatString(v)))
            );
          })
        : false;

      const refAttributeMatchesGender: boolean = demographic.length
        ? refAttributes.some(({ key, values }) => {
            return demographic.some(
              (demo) =>
                key === 'gender' && values.includes(reformatString(demo))
            );
          })
        : false;

      return refAttributesMatchesDetail && refAttributeMatchesGender;
    });

    const entryWithMatchingCategories = entries.items.find((e) => {
      const refAttributes: ReferenceAttribute[] =
        e.fields.referenceAttributes?.[sdk.field.locale];

      if (!refAttributes) {
        return undefined;
      }

      const refAttributesMatchesCategories: boolean = categories.length
        ? refAttributes.some(({ key, values }) => {
            return categories.some(
              (c) => key === 'category' && values.includes(reformatString(c))
            );
          })
        : false;

      const refAttributeMatchesGender: boolean = demographic.length
        ? refAttributes.some(({ key, values }) => {
            return demographic.some(
              (demo) =>
                key === 'gender' && values.includes(reformatString(demo))
            );
          })
        : false;

      return refAttributesMatchesCategories && refAttributeMatchesGender;
    });

    const fallbackGender = entries.items.find((e) => {
      const refAttributes: ReferenceAttribute[] =
        e.fields.referenceAttributes?.[sdk.field.locale];
      if (!refAttributes) {
        return undefined;
      }

      return refAttributes.some(({ key, values }) => {
        return demographic.every(
          (demo) => key === 'gender' && values.includes(reformatString(demo))
        );
      });
    });

    // Return a matching entry with decreasing order of specificity
    return (
      entryWithMatchingDetails || entryWithMatchingCategories || fallbackGender
    );
  };

  // Generates a breadcrumb as long as variants exist on the product and breadcrumbs arent already existing
  const generateBreadcrumb = async () => {
    if (!variants.length) return undefined;
    if (breadcrumbs) return undefined;

    setInProgress(true);

    // Demographic is level 1 breadcrumb
    const marketedAge: keyof typeof DEMOGRAPHIC_SLUG =
      sdk.entry.fields.marketed_age.getValue();
    const marketedGender = sdk.entry.fields.marketed_gender.getValue();
    let demographic: string;

    if (marketedAge === 'Adult') {
      demographic =
        DEMOGRAPHIC_SLUG?.[marketedAge]?.[
          marketedGender as keyof typeof DEMOGRAPHIC_SLUG['Adult']
        ];
    } else {
      demographic = DEMOGRAPHIC_SLUG?.[marketedAge]?.['Unisex'];
    }

    const breadcrumbEntriesParams: BreadcrumbLevels = {};
    breadcrumbEntriesParams.demographic = [demographic.toLowerCase()];

    const res = await cma.entry.get({ entryId: variants[0].sys.id });
    const sku = res.fields.sku[sdk.field.locale];

    const { itemAsVariant } = await client(sdk.ids.environment).request(
      gql`
        query Query($sku: String!) {
          itemAsVariant(sku: $sku)
        }
      `,
      { sku }
    );
    const { components } = itemAsVariant;

    const categories = (components || []).map(
      (c: GenericComponentFieldObject) => c.category
    );

    if (categories.length) {
      breadcrumbEntriesParams['categories'] = categories;

      const categoryDetails = componentFieldMap
        .map((field) => {
          const values = components
            .filter(
              (c: GenericComponentFieldObject) =>
                c[field] && field !== 'category'
            )
            .map((c: GenericComponentFieldObject) => c[field].toLowerCase());

          if (values.length) {
            return {
              key: field,
              values
            };
          }

          return undefined;
        })
        .filter(Boolean);

      if (categoryDetails.length) {
        breadcrumbEntriesParams['details'] =
          categoryDetails as BreadcrumbLevels['details'];
      }
    }

    const entry = await getBreadcrumbEntries(breadcrumbEntriesParams);

    if (entry) {
      sdk.entry.fields.breadcrumb.setValue({
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      });
    }

    setInProgress(false);
  };

  const handleAction = () => setBreadcrumbs(sdk.field.getValue());

  // Watch fields for changes
  useEffect(() => {
    const unsubscribe = sdk.entry.fields.variants.onValueChanged((vals) => {
      setVariants(vals || []);
    });

    return () => unsubscribe();
  }, [sdk.entry.fields.variants]);

  useEffect(() => {
    sdk.window.updateHeight();
  }, [breadcrumbs, sdk.window]);

  if (inProgress) return <Spinner size="large" />;

  return (
    <div>
      {!variants.length && !breadcrumbs && (
        <Text>
          Add variants to automatically generate breadcrumbs. The automatically
          generated breadcrumb can be updated manually if needed.
        </Text>
      )}
      {!!variants.length && !breadcrumbs && (
        <Button onClick={() => generateBreadcrumb()}>
          Generate Breadcrumbs
        </Button>
      )}
      <SingleEntryReferenceEditor
        viewType="link"
        sdk={sdk}
        hasCardEditActions
        isInitiallyDisabled={true}
        parameters={{
          instance: {
            showCreateEntityAction: true,
            showLinkEntityAction: true
          }
        }}
        onAction={handleAction}
      />
    </div>
  );
};

export default ProductBreadcrumb;
