import React from 'react';
import { 
  Card,
  Flex,
  SectionHeading,
  IconButton
} from '@contentful/f36-components'
import { CloseIcon } from '@contentful/f36-icons';

// Card to Display for Deleted Linked Entries
const DeletedEntryCard = ({ onRemove = undefined, isDisabled = false }: {
    onRemove: (() => void) | undefined,
    isDisabled: boolean
}) => (
    <Card>
    <Flex alignItems="center" justifyContent="space-between">
      <div>
        <SectionHeading>Entry is missing or inaccessible</SectionHeading>
      </div>
      <div>
        {!isDisabled && onRemove && (
          <IconButton
            variant="transparent"
            icon={<CloseIcon variant="muted" />}
            aria-label="deleted"
            onClick={() => {
              onRemove && onRemove();
            }}
          />
        )}
      </div>
    </Flex>
  </Card>
);

export default DeletedEntryCard;