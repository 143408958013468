import ReferenceAttributesField from '../ReferenceAttributeInput/ReferenceAttributeInput';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Text, Stack } from '@contentful/f36-components';
import { useEffect, useState } from 'react';
import { client, gql } from '../services/graphql';

type ReferenceAttributes = Record<string, Array<string>>;

const VALID_ATTRIBUTES: ReferenceAttributes = {};

const PersonalizedProductContentReferenceAttributesField = ({
  sdk
}: {
  sdk: FieldExtensionSDK;
}) => {
  const [validReferenceAttributes, setValidReferenceAttributes] = useState(VALID_ATTRIBUTES);

  useEffect(() => {
    client(sdk.ids.environment)
      .request(
        gql`
          query getReferenceAttributes {
            referenceAttributes {
              endUse
              category
              age
              gender
            }
          }
        `,
        {}
      )
      .then(({ 
        referenceAttributes: {
          endUse,
          category,
          age,
          gender,
        }
      }) => {
        setValidReferenceAttributes((v) => ({
          ...v,
          end_use: endUse,
          category,
          age,
          gender,
        })
      )
    });
  }, []);

  return (
    <Stack flexDirection="column" spacing="spacingS" alignItems="left">
      <ReferenceAttributesField
        sdk={sdk}
        attributes={validReferenceAttributes}
        hasExclude
      />
      <Text as="i" fontColor="gray500">
        When these conditions are true, this personalized product content can be
        displayed.
      </Text>
    </Stack>
  );
};

export default PersonalizedProductContentReferenceAttributesField;
